import { createSlice } from '@reduxjs/toolkit';
import {IFeatures, InitialState} from '../../../types';

export const initialState: IFeatures = {
  campaignRuleSets: {
    mp: {
      leaderboard: true,
      policy_name: null,
      position: '0',
      visible: true
    },
    graph: {
      position: '1',
      categories: [],
      show_insight_component: false,
      market_comparison: false,
      show_market_info: false,
      month_display_range: {
        start_date: null,
        end_date: null,
      },
      visible: true
    },
    bsi: {
      position: '2',
      att_button: false,
      insight_label: false,
      select_from_text_options: null,
      visible: true
    },
    content: {
      position: '3',
      define_repetitive_content: [],
      social_reaction_button: false,
      share_button: false,
      visible: true
    },
  },
  mpRuleSetChanged: false,
  graphRuleSetChanged: false,
  bsiRuleSetChanged: false,
  contentRuleSetChanged: false,
  currentCampaignRuleSetChanged: false,
  currentCampaignId: null,
  isOldVersion: true
};

export const CampaignFeaturesSlice = createSlice({
  name: 'CampaignFeaturesSlice',
  initialState,
  reducers: {
    setCampaignRuleSet: (state: IFeatures, action) => {
      state.campaignRuleSets = {
        ...state.campaignRuleSets,
        ...action.payload,
      };
    },
    setMpRuleSetChanged: (state: IFeatures, action) => {
      if (action.payload) {
        state.currentCampaignRuleSetChanged = true;
      }
      state.mpRuleSetChanged = action.payload;
    },
    setGraphRuleSetChanged: (state: IFeatures, action) => {
      if (action.payload) {
        state.currentCampaignRuleSetChanged = true;
      }
      state.graphRuleSetChanged = action.payload;
    },
    setBsiRuleSetChanged: (state: IFeatures, action) => {
      if (action.payload) {
        state.currentCampaignRuleSetChanged = true;
      }
      state.bsiRuleSetChanged = action.payload;
    },
    setContentRuleSetChanged: (state: IFeatures, action) => {
      if (action.payload) {
        state.currentCampaignRuleSetChanged = true;
      }
      state.contentRuleSetChanged = action.payload;
    },
    setCampaignRuleSetsIsChanged: (state: IFeatures, action) => {
      state.currentCampaignRuleSetChanged = action.payload;
    },
    setCurrentCampaignId: (state: IFeatures, action) => {
      state.currentCampaignId = action.payload;
    },
  },
  });

export const {
  setCampaignRuleSet,
  setMpRuleSetChanged,
  setGraphRuleSetChanged,
  setBsiRuleSetChanged,
  setContentRuleSetChanged,
  setCurrentCampaignId,
  setCampaignRuleSetsIsChanged
} = CampaignFeaturesSlice.actions;

export default CampaignFeaturesSlice.reducer;
