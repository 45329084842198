import { Grid } from '@hexa-ui/components';
import { Star, TrendinigUp, User } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import { useSidebar } from 'admin-portal-shared-services';
import { useMemo } from 'react';
import { IntlProvider } from './i18n';
import Router from './Router';

export const menuIcons = {
  Audience: () => <User />,
  Campaign: () => <Star />,
  Analytics: () => <TrendinigUp />,
};
const MI_NEGOCIO_ADMIN = '/mi-negocio-admin'
export const AppBarConfigProvider = (): JSX.Element => {
  const memoListMenuWithAccess = useMemo(
    () => [
      {
        id: '1',
        title: 'Audience',
        icon: menuIcons.Audience,
        path: MI_NEGOCIO_ADMIN,
      },
      {
        id: '2',
        title: 'Campaign',
        icon: menuIcons.Campaign,
        path: MI_NEGOCIO_ADMIN + '/campaigns',
      },
      {
        id: '3',
        title: 'Analytics',
        icon: menuIcons.Analytics,
        path: '',
      },
    ],
    []
  );

  const memoSidebarParams = useMemo(
    () => ({
      items: [...memoListMenuWithAccess],
      utils: [],
    }),
    [memoListMenuWithAccess]
  );

  useSidebar(memoSidebarParams);

  const StyledGrid = styled(Grid.Container, {
    paddingTop: '$4',
    paddingLeft: '3.5rem',
    rowGap: '1rem',
    '@desktopSmall': {
      paddingTop: 0,
    },
  });
  return (
    <IntlProvider>
      <StyledGrid sidebar>
        <Router />
      </StyledGrid>
    </IntlProvider>
  );
};
