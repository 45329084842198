import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from '../../../types';
import { useGetPLUConfiguration } from './PLU';
import { useGetAudienceData, useGetDDC, useGetSegments } from './audienceData';
import {
  useGetCampaign,
  useGetCampaignMetaData,
  useGetCampaigns,
  useGetGraphCategories,
} from './campaigns';
import { useGetAudienceStats } from './audienceStats';

export const initialState: InitialState = {
  country: 'ZA',
  campaigns: [],
  campaignObject: {
    country: null,
    name: '',
    startDate: null,
    endDate: null,
    segment: [],
    deliveryCenter: [],
    accounts: [],
    features: null,
  },
  campaignRuleSets: null,
  campaign: null,
  campaignMetadata: null,
  audienceStats: null,
  audienceData: null,
  pluConfigurationFields: null,
  pluConfiguration: null,
  pluCalculation: null,
  openConfigureAudienceModal: false,
  openDialog: false,
  DDCs: [],
  segments: [],
  graphCategories: [],
  OpenScheduleAudienceModal: false,
  scheduleDate: null,
  modalStep: 1,
  campaignRuleSetsIsChanged: false,
};

export const audienceSlice = createSlice({
  name: 'audience',
  initialState,
  reducers: {
    setCountry: (state: InitialState, action) => {
      state.country = action.payload;
    },
    setOpenConfigureModal: (state: InitialState, action) => {
      state.openConfigureAudienceModal = action.payload;
    },
    setOpenScheduleModal: (state: InitialState, action) => {
      state.OpenScheduleAudienceModal = action.payload;
    },
    setOpenDialog: (state: InitialState, action) => {
      state.openDialog = action.payload;
    },
    setScheduleDate: (state: InitialState, action) => {
      state.scheduleDate = action.payload;
    },
    setModalSteps: (state: InitialState, action) => {
      state.modalStep = action.payload;
    },
    setCampaigns: (state: InitialState, action) => {
      state.campaigns = action.payload;
    },
    setAudienceStats: (state: InitialState, action) => {
      state.audienceStats = action.payload;
    },
    setAudienceData: (state: InitialState, action) => {
      state.audienceData = action.payload;
    },
    setPLUConfigurationFields: (state: InitialState, action) => {
      state.pluConfigurationFields = action.payload;
    },
    setPLUConfiguration: (state: InitialState, action) => {
      state.pluConfiguration = action.payload;
    },
    setPLUCalculation: (state: InitialState, action) => {
      state.pluCalculation = action.payload;
    },
    setCampaign: (state: InitialState, action) => {
      state.campaign = action.payload;
    },
    setGraphCategories: (state: InitialState, action) => {
      state.graphCategories = action.payload;
    },
    setCampaignObject: (state: InitialState, action) => {
      state.campaignObject = {
        ...state.campaignObject,
        ...action.payload,
      };
    },
    setCampaignRuleSets: (state: InitialState, action) => {
      state.campaignRuleSets = action.payload;
    },
    setCampaignMetaData: (state: InitialState, action) => {
      state.campaignMetadata = action.payload;
    },
    setCampaignRuleSetsIsChanged: (state: InitialState, action) => {
      state.campaignRuleSetsIsChanged = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(useGetPLUConfiguration.rejected, (state) => {
        state.pluConfiguration = null;
        // TODO : show some snackbar error
      })
      .addCase(useGetPLUConfiguration.fulfilled, (state, action) => {
        state.pluConfiguration = action.payload;
      })
      .addCase(useGetCampaigns.fulfilled, (state, action) => {
        state.campaigns = action.payload;
      })
      .addCase(useGetCampaign.fulfilled, (state, action) => {
        state.campaign = action.payload;
      })
      .addCase(useGetAudienceStats.fulfilled, (state, action) => {
        state.audienceStats = action.payload;
      })
      .addCase(useGetAudienceData.fulfilled, (state, action) => {
        state.audienceData = action.payload;
      })
      .addCase(useGetDDC.rejected, (state) => {
        state.DDCs = [];
      })
      .addCase(useGetDDC.fulfilled, (state, action) => {
        state.DDCs = action.payload;
      })
      .addCase(useGetSegments.rejected, (state) => {
        state.segments = [];
      })
      .addCase(useGetSegments.fulfilled, (state, action) => {
        state.segments = action.payload;
      })
      .addCase(useGetGraphCategories.fulfilled, (state, action) => {
        state.graphCategories = action.payload;
      })
      .addCase(useGetCampaignMetaData.fulfilled, (state, action) => {
        state.campaignMetadata = action.payload;
      });
  },
});

export const {
  setCountry,
  setOpenConfigureModal,
  setOpenScheduleModal,
  setOpenDialog,
  setModalSteps,
  setPLUConfigurationFields,
  setCampaign,
  setCampaignObject,
  setCampaignRuleSets,
  setCampaignRuleSetsIsChanged,
} = audienceSlice.actions;

export default audienceSlice.reducer;
