export const PROD = {
  PE: 'c22602d9-e10e-4b3b-bc62-112c35d4dbef',
  CO: '4a9296fb-a28c-46e1-b71f-75a9707980be',
  SV: '7384d74a-bfec-45cd-aca1-232d309a83c8',
  HN: 'a5bfd193-7ee7-48cb-96f7-5af632625ab2',
  EC: 'b962c222-212e-4995-a18c-f496621aa5f8',
  BR: 'de1f0b68-f635-44dd-92c5-15a04ac10a1d',
  MX: 'ab4c7d46-e2fe-4787-8726-db323c1ee1f9',
  PA: '4468ffbd-6c9f-4bfe-8b62-91365bc76004',
  DO: 'f4330d52-be6d-4829-afcd-507c6cddff4e',
  ZA: '166e28b1-9284-484d-898c-ad48cbac37ab',
};

export const UAT = {
  PE: 'cb8048a7-5275-4e50-bbdd-986f3b132289',
  CO: 'eb6a886d-32f1-4555-bc5d-9a2ee8992c5e',
  SV: '1d8cacb7-7762-4dfc-b64a-fc1b5dc874d0',
  HN: '9fea9ccb-f4df-4c3f-8e39-db39f18eda35',
  EC: '8e030f4b-25dc-4eec-af43-a1992097ee9f',
  BR: 'a01deb9a-e47a-4e18-88e4-43018c64f03e',
  MX: 'ce141576-4a9d-4bad-8fd8-5bf5cce1c06c',
  PA: '57ad8a23-8f32-46c2-81b4-dc2419afc2a7',
  DO: 'd6ad9fa2-b3a7-4771-84d4-9f7d228d2412',
  ZA: '18f694bf-ec6a-4cd5-b680-24926124f07f',
};

export const SIT = {
  PE: '7937d20b-b838-4682-827e-0f75b9380506',
  CO: '6d9ccf42-96b6-4121-a1d9-24920c656086',
  SV: 'f0f0b8bf-233e-4420-ae37-089569f028f3',
  HN: '9c604d7f-ae87-439b-8f17-e9d8849dd4d1',
  EC: 'fa76813d-0633-4afc-8596-ed699e13df6b',
  BR: 'a2a5433c-aba3-4850-932b-91bbf90924fc',
  MX: 'c5ac68ef-34e0-4649-b70b-6cd7153bfeef',
  PA: '85ac0e19-9793-4cd0-861b-44dd048efc4a',
  DO: '7b7efc58-6c96-43e6-95fe-0640d2aee5f9',
  ZA: 'dd345f1a-ce4b-4622-a2b6-ab1b89429c36',
};
