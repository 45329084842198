import { Provider } from 'react-redux';
import { AppBarConfigProvider } from './AppConfigProvider';
import { EnvConfig, EnvProvider } from './components/EnvProvider/EnvProvider';
import store from './shared/redux/store';

export default function App(props: EnvConfig) {
  return (
    <EnvProvider env={props}>
      <Provider store={store}>
        <AppBarConfigProvider />
      </Provider>
    </EnvProvider>
  );
}
