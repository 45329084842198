import type { PreloadedState } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import AudienceReducer from './reducers/audience/AudienceSlice';
import CampaignFeaturesReducer from './reducers/audience/CampaignFeaturesSlice';

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  AudienceStore: AudienceReducer,
  CampaignFeaturesStore: CampaignFeaturesReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
