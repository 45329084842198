import { lazy, Suspense } from 'react';
import {BrowserRouter, Outlet, Route, Routes} from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';

const AudienceManager = lazy(() => import('./pages/Audience/AudienceManager'));
const CampaignsManager = lazy(() => import('./pages/Campaigns/CampaignsManager'));
const ManageCampaign = lazy(() => import('./components/CampaignsSection/ManageCampaign/Features/ManageCampaign'));

const Router = (): JSX.Element => {
  return (
    <BrowserRouter basename={'/mi-negocio-admin'}>
      <Suspense fallback={<Spinner dataTest="transition-spinner" />}>
        <Routes>
          <Route path="" element={<AudienceManager />} />
          <Route path="campaigns" element={<Outlet />} >
            <Route index element={<CampaignsManager />} />
            <Route path="new" element={<ManageCampaign />} />
            <Route path="edit-campaign/:campaign_id" element={<ManageCampaign />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
