import { AudienceData } from '../../../types';
import {
  getAudienceData,
  getDDC,
  getSegments,
} from '../../../../services/audience/AudienceService';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_AUDIENCE_DATA } from '../../../../constants';

export const useGetAudienceData = createAsyncThunk(GET_AUDIENCE_DATA, async (payload) => {
  const response: AudienceData = await getAudienceData(payload);
  return response;
});

export const useGetDDC = createAsyncThunk('v1/distribution-centers', async (payload) => {
  let DDCOptions: any = await getDDC(payload);
  DDCOptions = DDCOptions.map((ddc: string, index: number) => {
    return {
      value: index,
      label: ddc,
    };
  });
  return DDCOptions;
});

export const useGetSegments = createAsyncThunk('v1/segments', async (payload) => {
  let segments: any = await getSegments(payload);
  segments = segments.map((segment: string, index: number) => {
    return {
      value: index,
      label: segment,
    };
  });
  return segments;
});
