import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import {
  getCampaign,
  getCampaignMetadata,
  getCampaigns,
  getGraphCategories,
} from '../../../../services/audience/AudienceService';
import {
  Campaign,
  CampaignRequest,
  GetCampaignRequest,
  GraphCategoriesRequest,
} from '../../../types';
import storeIdMapper from '../../../utils/storeIdMapper/storeIdMapper';

type State = {
  status: string;
  error: any;
  country: string;
  campaigns: Campaign[];
  openConfigureAudienceModal: boolean;
  modalStep: number;
};

interface IGraphCategoriesRequest {
  country: string;
  env: string;
}

export const useGetCampaigns = createAsyncThunk(
  'v1/campaigns',
  async (payload: GetCampaignRequest) => {
    const response: Campaign[] = await getCampaigns(payload);
    return response;
  }
);

export const useGetCampaign = createAsyncThunk(
  'v1/campaign',
  async (payload: GetCampaignRequest) => {
    const response: Campaign = await getCampaign(payload);
    return response;
  }
);

export const useGetGraphCategories = createAsyncThunk(
  'v1/graph-categories',
  async ({ country, env }: IGraphCategoriesRequest) => {
    const graphCategoriesRequest: GraphCategoriesRequest = {
      country: country,
      requestTraceId: v4(),
      storeId: storeIdMapper({
        country,
        env,
      }),
    };

    const response: object[] = await getGraphCategories(graphCategoriesRequest);

    return response;
  }
);

export const useGetCampaignMetaData = createAsyncThunk(
  'v1/campaign-metadata',
  async (payload: CampaignRequest) => {
    const response: Campaign = await getCampaignMetadata(payload);
    return response;
  }
);
