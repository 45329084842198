import Api from '../../Api/Api';
import {
  ADD_OR_UPDATE_CAMPAIGN,
  CALCULATE_PLU,
  CAMPAIGN_METADATA,
  GET_AUDIENCE_DATA,
  GET_AUDIENCE_STATS,
  GET_CAMPAIGN,
  GET_CAMPAIGNS,
  GET_DDC,
  GET_PLU_CONFIGURATION,
  GET_SEGMENTS,
  GRAPH_CATEGORIES,
  SCHEDULE_LAUNCH_DATE,
  SET_PLU_CONFIGURATION,
  SUCCESS_CODE,
} from '../../constants';
import {
  AudienceData,
  AudienceStats,
  Campaign,
  CampaignRequest,
  GetCampaignRequest,
  GraphCategoriesRequest,
  PLUCalculation,
  PLUConfiguration,
  PLUConfigurationFields,
  ScheduleConfigurationRequest,
} from '../../shared/types';

export const getPromise = (resp): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(resp.status)) {
      resolve(resp.data);
    } else {
      reject(resp);
    }
  });
};
export const getCampaigns = async (payload: GetCampaignRequest): Promise<Campaign[]> => {
  let resp: any;
  try {
    const response = await Api.get({
      url: GET_CAMPAIGNS,
      body: payload,
    });
    resp = response;
  } catch (error) {
    resp = error;
  }
  return getPromise(resp);
};

export const getCampaign = async (payload: GetCampaignRequest): Promise<Campaign> => {
  let resp: any;
  try {
    const response = await Api.get({
      url: GET_CAMPAIGN,
      body: payload,
    });
    resp = response;
  } catch (error) {
    resp = error;
  }
  return getPromise(resp);
};

export const getAudienceStats = async (reqHeader: any): Promise<AudienceStats> => {
  let resp: any;
  try {
    const response = await Api.get({
      url: GET_AUDIENCE_STATS,
      body: reqHeader,
    });
    resp = response;
  } catch (error) {
    resp = error;
  }

  return getPromise(resp);
};

export const getAudienceData = async (reqHeader: any): Promise<AudienceData> => {
  let resp: any;
  try {
    const response = await Api.get({
      url: GET_AUDIENCE_DATA,
      body: reqHeader,
    });
    resp = response;
  } catch (error) {
    resp = error;
  }
  return getPromise(resp);
};

export const getPLUConfiguration = async (reqHeader: any): Promise<PLUConfiguration> => {
  let resp: any;
  try {
    const response = await Api.get({
      url: GET_PLU_CONFIGURATION,
      body: reqHeader,
    });
    resp = response;
  } catch (error) {
    resp = error;
  }
  return getPromise(resp);
};

export const configurePLU = async (
  pluConfig: PLUConfigurationFields
): Promise<PLUConfiguration> => {
  let resp: any;
  try {
    const response = await Api.post({
      url: SET_PLU_CONFIGURATION,
      body: pluConfig,
    });
    resp = response;
  } catch (error) {
    resp = error;
  }
  return getPromise(resp);
};

export const scheduleLaunchDate = async (request: ScheduleConfigurationRequest): Promise<any> => {
  let resp: any;
  try {
    const response = await Api.post({
      url: SCHEDULE_LAUNCH_DATE,
      body: request,
    });
    resp = response;
  } catch (error) {
    resp = error;
  }
  return getPromise(resp);
};

export const calculatePLUConfig = async (
  pluConfig: PLUConfigurationFields
): Promise<PLUCalculation> => {
  let resp: any;
  try {
    const response = await Api.post({
      url: CALCULATE_PLU,
      body: pluConfig,
    });
    resp = response;
  } catch (error) {
    resp = error;
  }
  return getPromise(resp);
};

export const getDDC = async (reqHeader: any): Promise<string[]> => {
  let resp: any;
  try {
    const response = await Api.get({
      url: GET_DDC,
      body: { ...reqHeader, requestTraceId: '1234' },
    });
    resp = response;
  } catch (error) {
    resp = error;
  }

  return getPromise(resp);
};

export const getSegments = async (reqHeader: any): Promise<string[]> => {
  let resp: any;
  try {
    const response = await Api.get({
      url: GET_SEGMENTS,
      body: reqHeader,
    });
    resp = response;
  } catch (error) {
    resp = error;
  }

  return getPromise(resp);
};

export const addOrUpdateCampaign = async (campaignRequest: CampaignRequest): Promise<object> => {
  let resp: any;
  try {
    const response = await Api.post({
      url: ADD_OR_UPDATE_CAMPAIGN,
      body: campaignRequest,
    });
    resp = response;
  } catch (error) {
    resp = error;
  }

  return getPromise(resp);
};

// @ts-ignore
export const getGraphCategories = async (
  graphCategoriesRequest: GraphCategoriesRequest
): Promise<object[]> => {
  let resp: any;
  try {
    const response = await Api.get({
      url: GRAPH_CATEGORIES,
      body: {
        'Accept-Language': '',
        ...graphCategoriesRequest,
      },
    });
    resp = response;
  } catch (error) {
    resp = error;
  }

  return getPromise(resp);
};
// @ts-ignore
export const getCampaignMetadata = async (campaignRequest: CampaignRequest): Promise<Campaign> => {
  let resp: any;
  try {
    const response = await Api.get({
      url: CAMPAIGN_METADATA,
      body: campaignRequest,
    });
    resp = response;
  } catch (error) {
    resp = error;
  }

  return getPromise(resp);
};
