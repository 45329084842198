export const AUDIENCE_SERVICE = '/api/mi-negocio-audience-manager-service/v1';
export const GET_CAMPAIGNS = AUDIENCE_SERVICE + '/campaigns';
export const GET_CAMPAIGN = AUDIENCE_SERVICE + '/campaign';
export const GET_AUDIENCE_STATS = AUDIENCE_SERVICE + '/audience-accounts-stats';
export const GET_AUDIENCE_DATA = AUDIENCE_SERVICE + '/audience-data';
export const GET_PLU_CONFIGURATION = AUDIENCE_SERVICE + '/get-plu-configuration';
export const SET_PLU_CONFIGURATION = AUDIENCE_SERVICE + '/configure';
export const SCHEDULE_LAUNCH_DATE = AUDIENCE_SERVICE + '/schedule-configuration';
export const CALCULATE_PLU = AUDIENCE_SERVICE + '/calculate-configuration';
export const GET_DDC = AUDIENCE_SERVICE + '/distribution-centers';
export const GET_SEGMENTS = AUDIENCE_SERVICE + '/segments';
export const ADD_OR_UPDATE_CAMPAIGN = AUDIENCE_SERVICE + '/upsert-campaign';
export const GRAPH_CATEGORIES = AUDIENCE_SERVICE + '/graph-categories';
export const CAMPAIGN_METADATA = AUDIENCE_SERVICE + '/campaign-metadata';
export const CODE_200 = 200;
export const CODE_204 = 204;
export const SUCCESS_CODE = [CODE_200, CODE_204];
export const GRAPH_CATEGORIES_STORE_ID = 'dd345f1a-ce4b-4622-a2b6-ab1b89429c36';
export const MAX_CSV_ACCOUNT_LIST = 10000;
export const Countries = [
  {
    name: 'South-AF',
    value: 'ZA',
  },
  {
    name: 'Brazil',
    value: 'BR',
  },
  {
    name: 'Dominican Republic',
    value: 'DO',
  },
  {
    name: 'Peru',
    value: 'PE',
  },
  {
    name: 'Colombia',
    value: 'CO',
  },
  {
    name: 'Mexico',
    value: 'MX',
  },
  {
    name: 'El Salvador',
    value: 'SV',
  },
  {
    name: 'Ecuador',
    value: 'EC',
  },
  {
    name: 'Honduras',
    value: 'HN',
  },
  {
    name: 'Panama',
    value: 'PA',
  },
];
