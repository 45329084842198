/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import Axios from 'axios';
import { getApiHost } from '../services/host/HostService';

export interface IRequest {
  url: string;
  body?: any;
}

const initialAxios = Axios.create();

const authentication = useAuthenticationService();
const AuthHeader = authentication.getAuthHeader();
const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

// @ts-ignore
authentication.enhancedAxios(initialAxios, {
  headers: [
    {
      Authorization: AuthHeader,
    },
  ],
});

const Api = {
  post: ({ url, body }: IRequest): Promise<any> =>
    initialAxios.post(`${getApiHost()}${url}`, body).catch((error) => ({
      hasError: true,
      ...error,
    })),

  delete: ({ url }: IRequest): Promise<any> =>
    initialAxios.delete(`${getApiHost()}${url}`).catch((error) => ({ hasError: true, ...error })),

  get: ({ url, body }: IRequest): Promise<any> =>
    initialAxios
      .get(`${getApiHost()}${url}`, { headers: body })
      .catch((error) => ({ hasError: true, ...error })),

  put: ({ url, body }: IRequest): Promise<any> =>
    initialAxios.put(`${getApiHost()}${url}`, body).catch((error) => ({
      hasError: true,
      ...error,
    })),

  patch: ({ url, body }: IRequest): Promise<any> =>
    initialAxios.patch(`${getApiHost()}${url}`, body).catch((error) => ({
      hasError: true,
      ...error,
    })),
};

export default Api;
