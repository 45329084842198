import { PROD, SIT, UAT } from './envs';

interface StoreIdMapper {
  country: string;
  env: string;
}

function storeIdMapper({ country, env }: StoreIdMapper) {
  const mapper = {
    SIT,
    UAT,
    PROD,
    QA: SIT,
  };

  return mapper[env][country] ?? mapper.SIT.ZA;
}

export default storeIdMapper;
